<template>
    <div class="admin-list">
        <div class="row g-2 mb-4">
            <div class="col-md-12 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search Admin"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                    <template #prepend>
                        <span class="fas fa-search"></span>
                    </template>
                </ui-input>
            </div>
        </div>
        <ui-table :items="items" :fields="fields" :busy="loading" :scopes="['write_admins']">
            <template #cell(name)="data">
                <ui-link :to="`/admins/${data.item.id}/edit`">
                    {{ data.item.first_name }} {{ data.item.last_name || '' }}
                </ui-link>
                <div>
                    <ui-link underline weight="regular" :link="'mailto:' + data.item.email">
                        <span>{{ data.item.email }}</span>
                    </ui-link>
                </div>
            </template>
            <template #cell(role)="data">
                <span class="text-capitalize">{{ data.item.role }}</span>
            </template>
            <template #cell(status)="data">
                <ui-status
                    class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                    :status="data.item.active ? 'active' : 'inactive'"
                />
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                    <template #button-content>
                        <span class="fas fa-ellipsis-v"></span>
                    </template>
                    <b-dropdown-item :to="`/admins/${data.item.id}/edit`">
                        Edit
                    </b-dropdown-item>
                    <b-dropdown-item @click="changeStatus(data.item.id, !data.item.active)">
                        Mark as {{ data.item.active ? 'Inactive' : 'Active' }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </ui-table>
        <ui-pagination v-model="this.filters.page" :pages="total_pages" class="mt-3" />
    </div>
</template>

<script>
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import UiTable from '@/components/ui/Table.vue';
import UiInput from '@/components/ui/Input';

import admins_mixin from '@/mixins/admins.mixin';
import table_mixin from '@/mixins/tables.mixin';

export default {
    name: 'AdminList',
    components: {
        UiLink,
        UiPagination,
        UiStatus,
        UiTable,
        UiInput
    },
    mixins: [admins_mixin, table_mixin],
    methods: {
        async changeStatus(id, active) {
            try {
                const { data } = await this.$axios.put(`/_/admins/update/${id}`, { active });
                const searchIndex = this.items.findIndex(admin => admin.id === data.id);

                this.items.splice(searchIndex, 1, data);
                this.$toast.success('Admin updated successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    },
};
</script>

<style lang="scss">
.admin-list {
    &__link {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
