<template>
    <div class="invitation-list">
        <ui-table :items="items" :fields="fields" :busy="loading" :scopes="['write_admins']">
            <template #cell(email)="data">
                <ui-link
                    v-if="data.item.email"
                    weight="medium"
                    :link="`mailto:${data.item.email}`"
                >
                    {{ data.item.email }}
                </ui-link>
                <span v-else class="badge bg-light text-dark">
                    No information
                </span>
            </template>
            <template #cell(role)="data">
                <span class="text-capitalize">{{ data.item.role }}</span>
            </template>
            <template #cell(status)="data">
                <ui-status
                    class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                    :status="data.item.status"
                />
            </template>
            <template #cell(actions)="data">
                <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                    <template #button-content>
                        <span class="fas fa-ellipsis-v"></span>
                    </template>
                    <b-dropdown-item :disabled="data.item.status === 'accepted'" @click="resendInvitation(data.item.id)">
                        Resend invitation
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </ui-table>
        <ui-pagination v-model="this.filters.page" :pages="total_pages" class="mt-3" />
    </div>
</template>

<script>
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiStatus from '@/components/ui/BadgeStatus.vue';
import UiTable from '@/components/ui/Table.vue';

import admins_mixin from '@/mixins/admins.mixin';
import table_mixin from '@/mixins/tables.mixin';

export default {
    name: 'InvitationList',
    components: {
        UiLink,
        UiPagination,
        UiStatus,
        UiTable,
    },
    mixins: [admins_mixin, table_mixin],
    data() {
        return {
            resourceUrl: '/_/invites',
            fields: [
                { key: 'email', label: 'Email' },
                { key: 'role', label: 'Role' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
        };
    },
    methods: {
        async resendInvitation(id) {
            try {
                await this.$axios.put(`/_/invites/${id}`);

                this.$toast.success('Invitation sent successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    },
};
</script>

<style lang="scss">
.invitation-list {
    &__link {
        cursor: pointer;
        text-decoration: underline;
    }
}
</style>
