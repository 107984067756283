export default {
    data() {
        return {
            fields: [
                { key: 'name', label: 'Name' },
                { key: 'role', label: 'Role' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
            },
            items: null,
            loading: false,
            resourceUrl: '/_/admins',
            total_pages: null,
        };
    },
    watch: {
        filters: {
            deep: true,
            immediate: false,
            handler() {
                this.getItems();
            },
        },
    },
    methods: {
        openPhoneLink(phone) {
            window.open(this.$sanitize(`tel:+${phone}`), '_self');
        },
        async getItems() {
            try {
                this.loading = true;
                const params = {
                    q: this.filters.q,
                    page: this.filters.page,
                    limit: this.filters.limit,
                };
                this._clearFilters(params);
                const { data } = await this.$axios.get(this.resourceUrl, { params });
                this.total_pages = data.pages;
                this.items = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
