<template>
    <div class="admin-page">
        <div v-if="CAN(['write_admins'])">
            <div class="row">
                <div class="col-auto ms-auto">
                    <ui-button variant="primary" :to="`/admins/invite`">
                        Invite admin
                    </ui-button>
                </div>
            </div>
            <div class="admin-page__tabs custom-tabs mb-4" >
                <ul class="admin-page__nav-tabs nav nav-tabs p-0" id="myTab" role="tablist">
                    <li class="admin-page__tabs-item nav-item" role="presentation">
                        <button
                            id="admins-tab"
                            class="admin-page__tabs-link nav-link bg-none active"
                            data-bs-toggle="tab"
                            data-bs-target="#admins-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="admins-tab-pane"
                            aria-selected="true"
                        >
                            Admins
                        </button>
                    </li>
                    <li class="admin-page__tabs-item nav-item" role="presentation">
                        <button
                            id="invitations-tab"
                            class="admin-page__tabs-link nav-link bg-none"
                            data-bs-toggle="tab"
                            data-bs-target="#invitations-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="invitations-tab-pane"
                            aria-selected="false"
                        >
                            Invitations
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tab-content" id="myTabContent">
            <div
                class="tab-pane fade show active"
                id="admins-tab-pane"
                role="tabpanel"
                aria-labelledby="admins-tab"
                tabindex="0"
            >
                <admin-list />
            </div>
            <div
                class="tab-pane fade"
                id="invitations-tab-pane"
                role="tabpanel"
                aria-labelledby="invitations-tab"
                tabindex="0"
            >
                <invitation-list />
            </div>
        </div>
    </div>
</template>

<script>
import AdminList from '@/components/admins/List.vue';
import InvitationList from '@/components/admins/InvitationList.vue';
import UiButton from '@/components/ui/buttons/Button'

export default {
    components: { AdminList, InvitationList, UiButton },
};
</script>

<style lang="scss">
.admin-page {
    &__tabs {
        border-bottom: 2px solid $ecart-secondary-200;
        padding: 0 !important;
    }

    &__nav-tabs {
        max-width: 100% !important;
    }

    &__tabs-item {
        margin-left: 0 !important;
    }

    &__tabs-link {
        border-bottom: 2px solid transparent !important;
        color: $general-black !important;
        font-weight: 500;

        &.active {
            border-bottom: 2px solid $ecart-primary !important;
        }
    }
}
</style>
